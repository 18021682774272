var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getListDevices,"data-test":"dataTable-field","items-per-page":10,"footer-props":{'items-per-page-options': [10, 25, 50, 100]},"server-items-length":_vm.getNumberDevices,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.online",fn:function(ref){
var item = ref.item;
return [(item.online)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" check_circle ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" check_circle ")])]}}],null,true)},[_c('span',[_vm._v("last seen "+_vm._s(_vm._f("lastSeen")(item.last_seen)))])])]}},{key:"item.hostname",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'detailsDevice', params: { id: item.uid } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.tag",fn:function(ref){
var item = ref.item;
return [(item.tags)?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.showTag(item.tags[0])},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.tags != null && item.tags.length > 0)?_c('v-chip',_vm._g({staticClass:"short justify-center"},on),[_vm._v(" "+_vm._s(_vm.displayOnlyTenCharacters(item.tags[0]))+" ")]):_vm._e()]}}],null,true)},[_c('span',[(_vm.showTag(item.tags[0]))?_c('div',[_vm._v(" "+_vm._s(item.tags[0])+" ")]):_vm._e()])]),(item.tags != null && item.tags.length > 0)?_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.tags != null && item.tags.length >= 2)?_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","small":""}},'v-btn',attrs,false),on),[_c('div',{staticClass:"test justify-center",attrs:{"text":"","small":"","flat":""}},[_vm._v(" "+_vm._s(("+ " + (item.tags.length - 1)))+" ")])]):_vm._e()]}}],null,true)},[_c('v-list',_vm._l((item.tags.slice(1,item.tags.length)),function(tag,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(tag))])],1)}),1)],1):_vm._e()],1):_vm._e()]}},{key:"item.info.pretty_name",fn:function(ref){
var item = ref.item;
return [_c('DeviceIcon',{attrs:{"icon-name":item.info.id,"data-test":"deviceIcon-component"}}),_vm._v(" "+_vm._s(item.info.pretty_name)+" ")]}},{key:"item.namespace",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"list-itens"},[_vm._v(" "+_vm._s(_vm.address(item))+" "),_c('v-icon',{attrs:{"small":"","right":""},on:{"click":function($event){_vm.copySSHID(_vm.address(item))}}},[_vm._v(" mdi-content-copy ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{ref:'menu'+_vm.getListDevices.indexOf(item),attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g({attrs:{"color":"transparent"}},on),[_c('v-icon',_vm._g(_vm._b({staticClass:"icons",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true)},[_c('v-card',[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.detailsDevice(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" info ")]),_c('v-list-item-title',[_vm._v(" Details ")])],1),(false)?_c('v-list-item',[(false)?_c('TagFormDialog',{attrs:{"action":"create","uid":item.uid,"data-test":"tagFormDialog-component"},on:{"update":function($event){return _vm.getDevices()}}}):_vm._e()],1):_vm._e(),(item.online)?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();_vm.showTerminalDialog(_vm.getListDevices.indexOf(item))}}},[_c('TerminalDialog',{attrs:{"uid":item.uid,"show":_vm.terminalDialogShow[_vm.getListDevices.indexOf(item)],"data-test":"terminalDialog-component"},on:{"update:show":function($event){_vm.$set(_vm.terminalDialogShow, _vm.getListDevices.indexOf(item), $event)}}})],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){_vm.showDeviceDelete(_vm.getListDevices.indexOf(item))}}},[_c('DeviceDelete',{attrs:{"uid":item.uid,"show":_vm.deviceDeleteShow[_vm.getListDevices.indexOf(item)],"data-test":"deviceDelete-component"},on:{"update:show":function($event){_vm.$set(_vm.deviceDeleteShow, _vm.getListDevices.indexOf(item), $event)},"update":_vm.refresh}})],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }